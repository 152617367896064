import { ClipboardCopy } from "components";
import renderDate from "utils/renderDate";

const Header = ({
  id,
  delivery_fullname,
  ordered_at,
  external_id,
}) => (
  <div className="order-details__header">
    <h1>
      <span>Zamówienie</span>{" "}
      <ClipboardCopy id={id} text={id} />
    </h1>
    <p>
      <ClipboardCopy id={external_id} text={external_id} />,{" "}
      {delivery_fullname}, {renderDate(ordered_at)}
    </p>
  </div>
);

export default Header;
