import {
  Input,
  // Select,
  MultiSelect,
  // AsyncMultiSelect,
  DatePicker,
  Select,
} from "./components";
import { useMainList } from "components/MainList/context/provider";
import Sort from "./components/Sort";

const renderComponent = (data, setValue, aggs) => {
  switch (data.type) {
    case "input": {
      const onChange = (value) => setValue([{ value }]);
      return (
        <Input
          label={data?.label}
          name={data?.name}
          onChange={onChange}
          value={data?.values?.[0]?.value}
        />
      );
    }
    case "date": {
      const onChange = (value) => setValue([{ value: value }]);

      return (
        <DatePicker
          label={data?.label}
          name={data?.name}
          onChange={onChange}
          value={data.values?.[0]?.value}
        />
      );
    }
    // case "async-multi-select": {
    //   const handleChange = (
    //     items,
    //     { action, option, removedValue }
    //   ) => {
    //     switch (action) {
    //       case "select-option": {
    //         const new_values = [...data.values];

    //         const index = new_values.indexOf(
    //           new_values.find(
    //             (item) =>
    //               item.name === option.label &&
    //               item.value === option.value
    //           )
    //         );
    //         if (index > -1) {
    //           new_values[index].value = option.value;
    //         } else {
    //           new_values.push({
    //             name: data.name,
    //             value: option.value,
    //             search_type: data.search_type,
    //           });
    //         }
    //         return setValue(new_values);
    //       }
    //       case "remove-value": {
    //         let new_values = [...data.values];
    //         new_values = new_values.filter(
    //           (item) =>
    //             item.name !== removedValue.label &&
    //             item.value !== removedValue.value
    //         );
    //         return setValue(new_values);
    //       }
    //       case "clear": {
    //         let new_values = [...data.values];
    //         new_values = new_values.filter(
    //           (item) => item.name !== data.name
    //         );
    //         return setValue(new_values);
    //       }
    //       default:
    //         return null;
    //     }
    //   };
    //   return (
    //     <AsyncMultiSelect
    //       data={data}
    //       handleChange={handleChange}
    //     />
    //   );
    // }
    case "multi-select": {
      const onChange = (items) =>
        setValue(items.map(({ value }) => ({ value })));

      const options =
        aggs[data?.name]?.map(({ name, value, count }) => ({
          label: `${name} (${count})`,
          value,
        })) || data?.options;

      return (
        <MultiSelect
          label={data.label}
          value={data.values.map(({ value }) => value)}
          options={options}
          onChange={onChange}
        />
      );
    }
    case "select": {
      const onChange = (item) =>
        setValue(item?.value ? [{ value: item.value }] : []);

      const options =
        aggs[data?.name]?.map(({ name, value, count }) => ({
          label: `${name} (${count})`,
          value,
        })) || data?.options;

      return (
        <Select
          label={data.label}
          value={data?.values?.[0]?.value}
          options={options}
          onChange={onChange}
        />
      );
    }
    default:
      return null;
  }
};

const MainFilters = () => {
  const {
    aggs,
    chart_filters,
    state: { main_filters = [], main_filters_open, meta },
    toggleMainFilters,
    setMainFilterValue,
    setMetaPagination,
  } = useMainList();

  return (
    <div className="main-list__filters">
      <div className="filters-wrapper">
        <div className={`filters-grid ${main_filters_open ? "open" : ""}`}>
          {main_filters.map((item) => {
            const setValue = (values) => [
              setMainFilterValue({
                name: item.name,
                values: values,
              }),
              setMetaPagination({
                page: 1,
                per_page: meta.per_page,
              }),
            ];
            return (
              <div key={item.name} className="form-group">
                {renderComponent(item, setValue, aggs)}
              </div>
            );
          })}
        </div>
        {chart_filters?.length > 0 && (
          <div className="chart-filters-grid">
            {chart_filters
              .filter(({ name }) => Object.keys(aggs).includes(name))
              .map(({ name, renderChart }) =>
                renderChart({
                  data: aggs[name],
                  setMainFilterValue,
                })
              )}
          </div>
        )}
      </div>
      <div className="filters-bottom">
        <Sort />
        {main_filters.length > 0 && (
          <button
            type="button"
            className="btn-collapse"
            onClick={toggleMainFilters}
          >
            <span>{!main_filters_open ? "Pokaż" : "Zwiń"} filtry</span>
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              style={{
                transform: `scaleY(${!main_filters_open ? "1" : "-1"})`,
              }}
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default MainFilters;
