import { ProductImage } from "components";
import { Button, ModalDelete } from "expano-components";
import useDelete from "hooks/useDelete";
import { useState } from "react";
import renderPrice from "utils/renderPrice";
import EditOrderLineModal from "./components/EditOrderLineModal";
import useUpdate from "hooks/useUpdate";
import classNames from "classnames";

import { ReactComponent as ImageIcon } from "icons/image.svg";

const OrderLine = ({
  id,
  quantity,
  product_sku,
  product_name,
  unit_price,
  comment,
  product,
  currency_code,
  refetch,
  error,
  can_edit,
}) => {
  const [is_open_delete_modal, setOpenDeleteModal] =
    useState(false);
  const [is_open_edit_modal, setOpenEditModal] =
    useState(false);
  const { _delete } = useDelete({
    path: `/order_lines/${id}`,
    onSuccess: () => {
      refetch();
      setOpenDeleteModal(false);
    },
  });

  const { is_saving, update } = useUpdate({
    path: `/order_lines/${id}`,
    onSuccess: () => {
      refetch();
      setOpenEditModal(false);
    },
  });

  const name = product?.name || product_name;

  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name={`${name}?`}
          handleClose={() => setOpenDeleteModal(false)}
          handleDelete={_delete}
        />
      )}
      {is_open_edit_modal && (
        <EditOrderLineModal
          is_saving={is_saving}
          onSubmit={update}
          currency_code={currency_code}
          id={id}
          initial_values={{
            product_sku,
            quantity,
            unit_price,
          }}
          handleClose={() => setOpenEditModal(false)}
        />
      )}
      <tr key={id} className={classNames({ error })}>
        <td>
          <ProductImage
            image={product?.image_url}
            thumb={product?.image_thumb_url}
            alt={name}
            id={id}
            quantity={quantity}
          />
        </td>
        <td>{product?.id}</td>
        <td>
          {name}{" "}
          {product?.ean ? `[EAN ${product.ean}]` : ""}{" "}
          {product_sku ? `[SKU ${product_sku}]` : ""}
          {error ? (
            <>
              <br />
              <span className="text-error">{error}</span>
            </>
          ) : null}
        </td>
        <td>{comment ||'-'}</td>
        <td>{quantity}</td>
        <td>{renderPrice(unit_price, currency_code)}</td>
        {can_edit && (
          <td>
            <div className="buttons-group">
              <Button
                type="edit"
                onClick={() => setOpenEditModal(true)}
              />
              <Button
                type="delete-bin"
                onClick={() => setOpenDeleteModal(true)}
              />
              <a
                href={`https://pim.tradesk.pl/product_image_by_sku?sku=${product_sku}`}
                class="btn btn-icon"
              >
                <ImageIcon />
              </a>
            </div>
          </td>
        )}
      </tr>
    </>
  );
};

export default OrderLine;
