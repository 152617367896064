import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import {
  createContext,
  useMemo,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { api, parseQuery } from "utils/api";

import reducer, {
  SET_DATA,
  CHANGE_SORT,
  SET_TABLE_FILTER_VALUE,
  REMOVE_TABLE_FILTER_VALUE,
  SET_MAIN_FILTER_VALUE,
  TOGGLE_MAIN_FILTERS,
  CLEAR_MAIN_FILTERS,
} from "./reducer";
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { toast } from "react-toastify";

// CONTEXT ===================================
const MainListContext = createContext();

const createDefaultParams = (params) => {
  try {
    return Object.keys(params).map(
      (key) => `${key}=${params[key]}`
    );
  } catch (error) {
    return "";
  }
};

const createRansackParams = ({
  table_filters = [],
  custom_filters = [],
  main_filters = [],
}) => {
  const data = [];
  [
    ...table_filters,
    ...custom_filters,
    ...main_filters,
  ].forEach(({ search_type, values, name }) => {
    switch (search_type) {
      case "in":
        values.forEach(({ value }) => {
          data.push(`q[${name}_in][]=${value}`);
        });
        break;
      case "matches":
        values.forEach(({ value }) => {
          data.push(`q[${name}_matches]=%25${value}%25`);
        });
        break;
      case "date":
        values.forEach(({ value }) => {
          const min_date = moment(value).format(
            "YYYY-MM-DD:00:00:00"
          );
          const max_date = moment(value).format(
            "YYYY-MM-DD:23:59:59"
          );
          data.push(`q[${name}_gteq]=${min_date}`);
          data.push(`q[${name}_lteq]=${max_date}`);
        });
        break;
      default:
        break;
    }
  });
  return data;
};

const createElasticParams = ({
  table_filters = [],
  custom_filters = [],
  main_filters = [],
}) => {
  const data = [];
  [
    ...table_filters,
    ...custom_filters,
    ...main_filters,
  ].forEach(({ search_type, values, name }) => {
    switch (search_type) {
      case "matches":
        values.forEach(({ value }) => {
          data.push(
            `search[${name}]=${value.replace(/\+/g, "%2B")}`
          );
        });
        break;
      case "in":
        values.forEach(({ value }) => {
          data.push(`search[${name}][]=${value}`);
        });
        break;
      case "range":
        values.forEach(({ value }) => {
          const date_start = value[0]
            ? moment(value[0]).format()
            : null;
          const date_end = value[1]
            ? moment(value[1]).format()
            : null;
          if (date_start) {
            data.push(`search[${name}][gte]=${date_start}`);
          }
          if (date_end) {
            data.push(`search[${name}][lte]=${date_end}`);
          }
        });
        break;
      default:
        values.forEach(({ value }) => {
          data.push(`search[${name}]=${value}`);
        });
        break;
    }
  });
  return data;
};

const createInitialState = (data, default_meta) => {
  const table_filters = [];
  const main_filters = data.main_filters.map((item) => ({
    ...item,
    values: [],
  }));
  for (const sort of data.sort) {
    const filter = data?.table_filters?.find(
      ({ sort_id }) => sort_id === sort.name
    );
    if (filter?.name) {
      table_filters.push({ ...filter, values: [] });
    } else {
      table_filters.push(null);
    }
  }
  const state = {
    table_filters,
    main_filters,
    sort: data.sort,
    active_sort: {
      column: data?.sort_column || "id",
      direction: data?.sort_direction || "desc",
    },
    is_initial: true,
    main_filters_open: false,
    data: [],
    aggs: [],
    meta: default_meta,
  };

  return state;
};

function MainListProvider({ props, children }) {
  const { search } = useLocation();

  const {
    page: a,
    per_page: b,
    ...search_query
  } = parseQuery(search);

  let [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const per_page =
    searchParams.get("per_page") || props.default_per_page;

  const default_meta = {
    current_page: 1,
    next_page: null,
    per_page: props.default_per_page || 50,
    prev_page: null,
    total_count: 1,
    total_pages: 1,
  };

  const [state, dispatch] = useReducer(
    reducer,
    createInitialState(props, default_meta)
  );

  const active_table_filters =
    state.table_filters?.filter(
      (filter) =>
        filter?.values?.filter(({ value }) => !!value)
          ?.length > 0
    ) || [];

  const active_main_filters =
    state.main_filters?.filter(
      (filter) =>
        filter?.values?.filter(({ value }) => !!value)
          ?.length > 0
    ) || [];

  const search_params = [
    ...createDefaultParams({
      "sort[column]": state.active_sort.column,
      "sort[order]": state.active_sort.direction,
      page,
      per_page,
    }),
    ...(props.search_type === "elastic"
      ? createElasticParams({
          table_filters: active_table_filters,
          custom_filters: props.custom_filters,
          main_filters: active_main_filters,
        })
      : createRansackParams({
          table_filters: active_table_filters,
          custom_filters: props.custom_filters,
          main_filters: active_main_filters,
        })),
  ]?.join("&");

  const query_context = props.context
    ? `context=${props.context}&`
    : "";

  const {
    isLoading: is_loading,
    isFetching: is_fetching,
    isPending: is_pending,
    refetch,
    data,
  } = useQuery({
    queryKey: [...props.query_key, search_params],
    queryFn: () =>
      api.get(
        `${props.api_path}?${query_context}${search_params}`
      ),
    onError: (err) =>
      toast.error(
        err?.response?.data?.message ||
          "Wystąpił błąd podczas pobierania"
      ),
    refetchInterval: props.refetch_interval || false,
    refetchIntervalInBackground:
      props.refetch_interval_in_background || false,
    enabled: Boolean(props.api_path),
  });

  const changeSort = ({ column, direction }) =>
    dispatch({
      type: CHANGE_SORT,
      payload: { column, direction },
    });

  const setTableFilterValue = ({ name, value, label }) =>
    dispatch({
      type: SET_TABLE_FILTER_VALUE,
      payload: { name, value, label },
    });

  const removeTableFilterValue = ({ name, value }) =>
    dispatch({
      type: REMOVE_TABLE_FILTER_VALUE,
      payload: { name, value },
    });

  const setMainFilterValue = ({ name, values }) =>
    dispatch({
      type: SET_MAIN_FILTER_VALUE,
      payload: { name, values },
    });

  const toggleMainFilters = () =>
    dispatch({
      type: TOGGLE_MAIN_FILTERS,
    });

  const setMetaPagination = ({ page, per_page }) =>
    setSearchParams({ page, per_page, ...search_query });

  useEffect(() => {
    if (data) {
      dispatch({ type: SET_DATA, payload: { ...data } });
    }
  }, [data]);

  useEffect(() => {
    dispatch({
      type: CLEAR_MAIN_FILTERS,
    });
  }, [search_query?.[props.query_reset_filters_key]]);

  const with_actions =
    typeof props?.renderActions === "function";

  const has_sub_list =
    typeof props?.subListData === "function";

  const value = useMemo(
    () => {
      return {
        title: props?.title || "",
        renderActions: props?.renderActions,
        bottom_action: props.bottom_action || null,
        activeIndexes: props?.activeIndexes,
        handleNavigate: props?.handleNavigate,
        empty_text: props?.empty_text,
        header_aside: props?.header_aside,
        sort_options: props.sort_options,
        chart_filters: props.chart_filters,
        is_loading,
        is_fetching,
        is_pending,
        with_actions,
        has_sub_list,
        state,
        data: state.data,
        aggs: state.aggs,
        meta: state.meta,
        changeSort,
        setTableFilterValue,
        removeTableFilterValue,
        setMainFilterValue,
        setMetaPagination,
        toggleMainFilters,
        renderItem: props.renderItem,
        subListData: props?.subListData,
        refetch,
      };
    },
    // eslint-disable-next-line
    [
      state,
      data,
      is_loading,
      is_fetching,
      props.activeIndexes,
      props.header_aside,
      search_query,
    ]
  );

  return (
    <MainListContext.Provider value={value}>
      {children}
    </MainListContext.Provider>
  );
}

const useMainList = () => useContext(MainListContext);
export { useMainList };
export default MainListProvider;
