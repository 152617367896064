import { useEffect, useMemo, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import useUpdate from "hooks/useUpdate";
import { Dashboard, Tag } from "components";
import PaymentStatus from "./components/PaymentStatus";
import FormActions from "../FormActions";
import Alert from "../Alert";
import SelectField from "./components/SelectField";
import delivery_methods_options from "utils/options/delivery_methods";
import getPaymentMethods from "utils/options/payment_methods";
import { useAuth } from "context/providers/auth";

const getExternalUrl = (channel_identifier, id) => {
  if (channel_identifier?.includes("Amazon")) {
    return `https://sellercentral.amazon.de/orders-v3/order/${id}`;
  }
  if (channel_identifier?.includes("Allegro")) {
    return `https://salescenter.allegro.com/orders/${id}`;
  }
  if (channel_identifier?.includes("Ebay")) {
    return `https://www.ebay.de/mesh/ord/details?mode=SH&srn=64090&orderid=${id}&source=Orders`;
  }
  if (channel_identifier?.includes("Wsf")) {
    return `https://wsf2.tradesk.pl/orders/${id}`;
  }
  return null;
};

const getExternalMessageUrl = (channel_identifier, id, client_login) => {
  if (channel_identifier?.includes("Amazon")) {
    return `https://sellercentral.amazon.de/messaging/inbox-v2?fi=SEARCH&pn=1&si=${id}`;
  }

  if (channel_identifier?.includes("Allegro")) {
    return `https://salescenter.allegro.com/orders/?query=${client_login}`;
  }

  return null;
};

const MainInfo = ({ refetch, is_fetching, ...data }) => {
  const { user } = useAuth();
  const [editing_field, setEditingField] = useState(null);
  const ref = useRef();

  const { is_saving, update } = useUpdate({
    path: `/orders/${data.id}`,
    onSuccess: async () => {
      await refetch();
      setEditingField(null);
    },
  });

  const external_url = getExternalUrl(
    data?.channel?.identifier,
    data?.external_number
  );

  const external_message_url = getExternalMessageUrl(
    data?.channel?.identifier,
    data?.external_number,
    data?.client_login
  );

  const payment_method_options = getPaymentMethods(
    user?.company?.expano_root_id
  );

  const items = [
    [
      {
        label: "Klient (login)",
        field: "client_login",
        field_type: "text",
      },
      {
        label: "E-mail",
        field: "client_email",
        field_type: "email",
      },
      {
        label: "Telefon",
        field: "client_phone",
        field_type: "tel",
      },
      {
        label: "Pochodzenie",
        field: "channel_identifier",
        field_label: data?.channel?.identifier,
        field_type: "none",
      },
      {
        label: "ID zewnętrzne",
        field: "external_id",
        field_label: external_url ? (
          <>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href={external_url}
            >
              {data?.external_number}
            </a>
            {external_message_url && (
              <a
                className="text-link m-l-10"
                target="_blank"
                rel="noopener noreferrer"
                href={external_message_url}
              >
                <svg
                  fill="#0077da"
                  height="20px"
                  width="20px"
                  viewBox="0 0 458 458"
                >
                  <path
                    d="M428,41.534H30c-16.569,0-30,13.431-30,30v252c0,16.568,13.432,30,30,30h132.1l43.942,52.243
			c5.7,6.777,14.103,10.69,22.959,10.69c8.856,0,17.258-3.912,22.959-10.69l43.942-52.243H428c16.568,0,30-13.432,30-30v-252
			C458,54.965,444.568,41.534,428,41.534z M323.916,281.534H82.854c-8.284,0-15-6.716-15-15s6.716-15,15-15h241.062
			c8.284,0,15,6.716,15,15S332.2,281.534,323.916,281.534z M67.854,198.755c0-8.284,6.716-15,15-15h185.103c8.284,0,15,6.716,15,15
			s-6.716,15-15,15H82.854C74.57,213.755,67.854,207.039,67.854,198.755z M375.146,145.974H82.854c-8.284,0-15-6.716-15-15
			s6.716-15,15-15h292.291c8.284,0,15,6.716,15,15C390.146,139.258,383.43,145.974,375.146,145.974z"
                  />
                </svg>
              </a>
            )}
          </>
        ) : (
          data?.external_number
        ),
        field_type: "none",
      },
    ],
    [
      {
        label: "Sposób wysyłki",
        field: "delivery_method",
        field_type: "select",
        field_options: delivery_methods_options,
      },
      {
        label: "Koszt wysyłki	",
        field: "shipping_price",
        field_type: "number",
      },
      {
        label: "Pobranie",
        field: "is_cod",
        field_type: "select",
        field_label: data?.is_cod ? "Tak" : "Nie",
        field_options: [
          { label: "Tak", value: true },
          { label: "Nie", value: false },
        ],
      },
      {
        label: "Sposób płatności",
        field: "payment_method",
        field_type: "select",
        field_options: payment_method_options,
      },
      {
        label: "Kom. klienta",
        field: "client_comment",
        field_type: "text",
      },
      {
        label: "Uwagi",
        field: "note",
        field_type: "text",
      },
    ],
  ];

  useEffect(() => {
    if (!!editing_field) {
      setTimeout(() => {
        ref.current.querySelector(`input[name="${editing_field}"]`)?.focus();
      }, 100);
    }
  }, [editing_field]);

  const initial_values = useMemo(
    () =>
      Object.fromEntries(
        items
          .flat()
          .filter(({ field_type }) => field_type !== "none")
          .map(({ field, field_value }) => [
            field,
            field_value || data?.[field],
          ])
      ),
    //eslint-disable-next-line
    [data]
  );

  const alerts = useMemo(
    () => {
      const fields = [
        "already_paid",
        ...items.flat().map(({ field }) => field),
      ];
      const errors = data.order_errors.filter(({ field_name }) =>
        fields.includes(field_name)
      );
      return errors.map(({ content }) => ({
        type: "danger",
        content: <p>{content}</p>,
      }));
    },
    //eslint-disable-next-line
    [data.order_errors]
  );

  const onSubmit = (values) => update(values);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initial_values}
        onSubmit={onSubmit}
      >
        <Dashboard
          full
          header={{
            title: "Informacje o zamówieniu",
            extra: (
              <>
                {data?.need_confirm_surcharge_zone ? (
                  <Tag type="danger">Strefa dopłatowa</Tag>
                ) : null}
                {data?.high_priority_reservation ? (
                  <Tag type="danger">Priorytetowa rezerwacja</Tag>
                ) : null}
                {data?.single_picking_mode ? (
                  <Tag type="danger">Pojedyńcza kompletacja</Tag>
                ) : null}
              </>
            ),
          }}
          footer={
            editing_field && (
              <FormActions
                is_saving={is_saving}
                is_fetching={is_fetching}
                setEditingField={setEditingField}
              />
            )
          }
        >
          {!editing_field &&
            alerts.map(({ type, content }) => (
              <Alert type={type}>{content}</Alert>
            ))}
          <div className="order-details__info" ref={ref}>
            <PaymentStatus {...data} order_id={data?.id} refetch={refetch} />
            {editing_field ? (
              <Form>
                {items.map((group, index) => (
                  <table className="info-group" key={index}>
                    <tbody>
                      {group.map(
                        ({
                          label,
                          field,
                          field_type,
                          field_label,
                          field_options = [],
                        }) => (
                          <tr key={field}>
                            <td>{label}:</td>
                            <td>
                              {field_type === "none" ? (
                                field_label
                              ) : field_type === "select" ? (
                                <SelectField
                                  name={field}
                                  options={field_options}
                                />
                              ) : (
                                <Field name={field}>
                                  {({ field }) => (
                                    <input
                                      type={field_type}
                                      placeholder={label}
                                      {...field}
                                    />
                                  )}
                                </Field>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ))}
              </Form>
            ) : (
              items.map((group, index) => (
                <table className="info-group" key={index}>
                  <tbody>
                    {group.map(({ label, field, field_label, field_type }) => (
                      <tr key={field}>
                        <td>{label}:</td>
                        <td>
                          {field_type === "none" ? (
                            field_label
                          ) : (
                            <button
                              onClick={() => setEditingField(field)}
                              type="button"
                              className="editable"
                            >
                              <div className="icon">
                                <FontAwesomeIcon icon={faEdit} />
                              </div>
                              {field_label || data?.[field] || "..."}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))
            )}
          </div>
        </Dashboard>
      </Formik>
    </>
  );
};

export default MainInfo;
